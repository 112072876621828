/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

interface SEOProperties {
  description?: string;
  lang?: string;
  meta?: HTMLMetaElement[];
  title: string;
}

const SEO = ({
  description = "",
  lang = "en",
  meta = [],
  title,
}: SEOProperties): JSX.Element | null => {
  const { site } = useStaticQuery<GatsbyTypes.SeoMetaDataQueryQuery>(
    graphql`
      query SeoMetaDataQuery {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  if (!site?.siteMetadata) {
    return null;
  }

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
};

export default SEO;
