import React, { useState } from "react";
import SEO from "../../helpers/clientSide/seo";
import { PageLayout } from "../../layouts/pageLayout/PageLayout";

const giphyIds = ["6uGhT1O4sxpi8", "KKOMG9EB7VqBq", "vnFB72LzHtO4o"];

const NotFoundPage = (): JSX.Element => {
  const [giphyId] = useState<string>(() => {
    return giphyIds[Math.floor(Math.random() * giphyIds.length)];
  });
  return (
    <PageLayout centerContent>
      <SEO title="404: Not found" />
      <iframe
        style={{ pointerEvents: "none" }}
        title="Could not find what you're looking for …"
        src={`https://giphy.com/embed/${giphyId}`}
        width="480"
        height="240"
        frameBorder="0"
        className="giphy-embed"
        allowFullScreen
      />
    </PageLayout>
  );
};

export default NotFoundPage;
